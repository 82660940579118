import React, { useEffect, useState } from "react";
import GoogleReviews from "../../images/google-reviews.svg"
import "./GoogleRating.scss"
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
const GoogleRating = ({testimonials}) => {
  const [isClient, setClient] = useState(false);

  const [renderComponent, setRenderComponent] = useState(false);

  React.useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
      })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
          setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => {
          if (renderComponent === false) {
          setRenderComponent(true)
          }
      })      
  }, [])

  useEffect(() => {
    setClient(true);
  }, [])

  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  const latestReviewsScore = useStaticQuery(graphql`
        query {
            allStrapiGoogleReviewReviews {
                edges {
                    node {
                        id
                        starRating
                    }
                },
                pageInfo {
                    totalCount
                }
            }
        }
    `)
  const reviews = latestReviewsScore.allStrapiGoogleReviewReviews.edges;
  const reviewsCount = latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount;
  const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
  let sumOfScores = 0;

  reviews.map((review) => {
    const reviewStringValue = review.node.starRating;
    sumOfScores += wordToNumber[reviewStringValue];
    return (sumOfScores)
  })

  const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;

  return (
    <div className="google-rating">
      <div>
        {renderComponent && <img src={GoogleReviews} alt="Google Reviews Bentley Hurst" />}
      </div>
      <p>
        Rated <strong>{rating_avg ? parseFloat(rating_avg.toFixed(1)) : ''}/5</strong> from {testimonial_count ? testimonial_count : ''} Customer Reviews
      </p>
    </div>
  )
}
export default GoogleRating
